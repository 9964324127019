@import "./shared/styles/variables.scss";
@import "./shared/styles/fonts";

@keyframes blinker {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
