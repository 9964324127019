.yellow-wave {
    position:absolute;
    bottom: -0.5px; /*Hack to fix yellow line one some screens */
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }
  
  .yellow-wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 140px;
    transform: rotateY(180deg);
  }
  
  .yellow-wave .shape-fill {
    fill: #F6EDD5;
  }