.custom-shape-divider-bottom-1660150287 {
  position: absolute;
  bottom: -0.5px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1660150287 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1660150287 .shape-fill {
  fill: #322E1B;
}