.custom-shape-divider-bottom-1659102697 {
    position: absolute;
    bottom: -0.5px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1659102697 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
}

.custom-shape-divider-bottom-1659102697 .shape-fill {
    fill: #714930;
}

/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-bottom-1659102697 svg {
        width: calc(300% + 1.3px);
        height: 100px;
    }
}