@font-face {
    font-family: 'Coiny';
    src: url('../../../public/fonts/coiny-regular.regular.otf') format('otf'),
         url('../../../public/fonts/coiny-regular.regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BalooBhaijan' ;
    src: url('../../../public/fonts/BalooBhaijaan-Regular.ttf') format('truetype');
  }

  @media screen and (min-width: 480px) {
    html {
      font-size: 10px;
    }
  }
  
  @media screen and (min-width: 768px) {
    html {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 992px) {
    html {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 1280px) {
    html {
      font-size: 16px;
    }
  }